.GalleryModal{
    align-items: center;
    animation: Modal_slideInTop__m5jCF .5s forwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999;
    background-color: rgba(0,0,0,.5);
}

.GalleryModalCont{
    width: 100%;
    height: 100%;
    background-color: var(--blox-color-white)!important;
    overflow-x: hidden;
    overflow-y: hidden;
    max-block-size: 100%;
    box-shadow: 0 0 20px rgba(0,0,0,.5);
    position: relative;
}

.GalleryModal_CloseIconCont{
    width: 20px;
    height: 20px;
    border-radius: 0px;
    position: absolute;
    top: 34px;
    right: 24px;
    z-index: 200;
    background-color: hsla(0,0%,100%,.15);
    color: var(--blox-color-white);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Gallery_ImageGalleryCont{
    position: relative;
    transition: 2s;
    background-color: var(--blox-color-thunder);
    min-height: -webkit-fill-available!important;
    padding-top: 66px;
    height: 100%;
}

.GalleryTab_NavWrapper{
    height: calc(100% - 89px);
}

.StickyWrapper_Cont{
    width: 100%;
    transition: all .2s ease-in-out;
}



.GalleryNavtan_Cont{
    position: absolute;
    top: 16px;
    right: 80px;
    gap: 10px;
    margin-bottom: 0!important;
    display: flex;
    list-style-type: none;
    cursor: pointer;
}

.GalleryNavBtn{
    border-radius: 25px;
    border-bottom: none!important;
    width: 94px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0!important;
}

.GalleryNavBtn.active{
    background-color: var(--blox-color-thunder);
    color: var(--blox-color-ebb-divider)!important;
}
.GalleryNavBtn.inactive{
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-thunder)!important;
}

.GalleryNavBtnText{
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.GalleryTabContent{
    height: 100%;
}

.GalleryTab_DataCont{
    padding: 0 72px;
    height: 100%;
}

.GalleryImages_ImageCont{
    height: 100%;
    position: relative;
}

.GallerySwiperInitialized{
    flex-direction: column;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    touch-action: pan-y;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.GallerySwiperWrapper{
    transform: translate3d(0px, 0px, 0px);
    transition-duration: 0ms;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    box-sizing: content-box;
}

.GallerySwiperSlide{
    width: 100%;
    margin-right: 10px;
    height: 100%;
    background-color: var(--blox-color-dune);
    border-radius: 10px;
    flex-shrink: 0;
    max-height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    transition-property: transform;
}



.GallerySwiperImage{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.GalleryImages_ImageIconPagination{
    position: absolute;
    left: 0;
    bottom: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 14px;
}

.GalleryImages_PaginationImageIcon{
    flex-direction:column;
    touch-action: pan-y;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}

.GalleryIconPagination_Imagewrapper{
    transform: translate3d(0px, 0px, 0px);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    box-sizing: content-box;
}

.GalleryIconPagination_Icon{
    margin-right: 10px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    flex-shrink: 0;
    position: relative;
    transition-property: transform;
    display: block;
    object-fit: contain;
}

.GalleryIconImage{
    border-radius: 10px;
    cursor: pointer;
    width: 32px;
    aspect-ratio: auto 32 / 32;
    height: 32px;
}

.GallerySwiper_NavigationBtn{
    align-items: center;
    position: absolute;
    top: calc(50% - 20px);
    left: -50px;
    width: calc(100% + 100px);
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.GalleryFilterBtn_Cont{
    display: flex;
    align-items: center;
    gap: 10px;
}

.GalleryFooter{
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 0.5px solid var(--blox-color-pampas-bg-color);
    padding: 20px 72px;

}

.GalleryFotter_Heading{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.GalleryFotter_SubHeading{
    color: var(--blox-color-ebb-divider);
    line-height: 26px;
    font-size: 14px;
}

.GalleryFotter_RightCont{
    display: flex;
    gap: 30px;
}

.GalleryFotterText1{
    color: var(--blox-color-ebb-divider);
    line-height: 26px;
    font-size: 12px;
}

.GalleryFotterText1{
    font-size: 14px;
    line-height: 20px;
}

@media (max-width: 768px) {
    .GalleryTabContent{
        height: 80%;
        width: 100%;
        margin: 0;
    }
    .GalleryTab_DataCont{
        padding: 8px;
    }
    .GallerySwiperImage{
        height: 100%;
        object-fit: cover;
    }
    .GalleryImages_ImageIconPagination{
        bottom: 150px;
    }
    .GalleryFooter{
        padding: 8px;
        margin-bottom: 34px;
        display: flex;
        flex-direction: column;
    }
    .GalleryFooter_div{
        display: flex;
    }
    .GalleryFotter_SubHeading{
        display: flex;
        flex-direction: column;
    }
}