



.Propert_ViewerCard__CardCont {
    width: 100%;
    height: 100%;
}

.PropertyCard_MediaContent {
    width: 100%;
    height: 100vh;
    position: relative;
}

.PropertyViewerCard_MediaScroller {
    height: 492px;
    width: 100vw;
}

.PropertyViewerCard_BannerPanner {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ImagePanner {
    overflow: hidden;
}

.ImagePannerinactive {
    display: none;
}

.ImagePanneractive {
    display: block;
}

.PropertyViewerImg {
    width: 100%;
    animation-name: imagetopdown;
    animation-duration: 20s;
}

@keyframes imagetopdown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-35%);
    }
}

.PropertyCardViewer_MediaContent{
    width: 100%;
    height: 492px;
    position: relative;
}

.PropertyCardViewer_CardDataCont {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.PropertyCard_NavigatorCont {
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1000;
    padding: 77px;
    background: linear-gradient(180deg, rgba(35, 30, 31, .8), rgba(35, 30, 31, 0) 83.33%);
}

.PropertyCard_NavigatorRouteCont {
    text-align: center;
}

.PropertyCard_NavigatorRouteInlineLast,
.PropertyCard_NavigatorRouteInline {
    display: inline-flex;
    gap: 5px;
    margin-right: 5px;
}

.PropertyCard_NavigatorArrow {
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
}

.PropertyCard_DataCont {
    padding: 0 20px;
    background: linear-gradient(359.76deg, #342f30 39.55%, rgba(35, 30, 31, 0) 83.23%);
   
    z-index: 1000;
}

.PropertyViewerCard_PropertyData {
    display: flex;
    justify-content: space-between;
}

.PropertyViewerCard_PropertyNameIconCont {
    max-width: 490px;
}

.PropertyViewerCard_PropertyIcon {
    border-radius: 24.889px;
    margin-bottom: 12px;
}

.PropertyViewerCard_PropertyNameCont {
    margin-bottom: 32px;
    font-weight: 400;
}

.PropertyViewerCard_PropertyTitleText {
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -1px;
}

.PropertyViewerCard_PropertyLongAddressCont {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.PropertyViewerCard_PropertyActionBtnRangeCnt {
    margin-top: auto;
}

.PropertViewerCard_PropertyActionBtnCont {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: flex-end;
}

.PropertyViewerCard_Btn {
    width: 48px;
    height: 36px;
    border-radius: 100px;
    display: flex;
    background: hsla(0, 0%, 100%, .15);
    box-sizing: border-box;
    color: var(--blox-color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.PropertyViewerCard_BtnImg {
    width: 20px;
    aspect-ratio: auto 28 / 28;
    height: 20px;
    background:none;
    border:none;
    color:#fff !important
}
.PropertyViewerCard_BtnImg2{
    width:22px;
    height:22px;
    align-items: center;
    color: #fff;
    cursor: pointer;
}
.PropertyViewerCard_BtnImg2active{
    width:22px;
    height:22px;
    align-items: center;
    color: #fff;
    cursor: pointer;
    fill: red;
    transition: all 0.2s ease-in-out;
}
.PropertyViewerCard_BtnImg_butt{
    color:#fff;
    background: none;
    border: none;
    font-size: 25px;
}

.PropertyViewerCard_GalleryBtncontent_butt_icon{
    color: #fff;
}
.PropertViewerCard_PropertyRangeBtnCont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 40px;
}

.CheckIcon{
    color: #fff;
    font-size: 20px;
}

.PropertyViewerCard_RangeText {
    font-size: 20px;
    line-height: 24px;
}

.PropertyViewerCard_PropertyActionBtnSetright {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 12px;
    align-items: center;
    width: 350px;
    justify-content: flex-end;
    padding-bottom: 110px;
}

.PropertyViewerCard_GalleryBtncont {
    background: rgba(35, 30, 31, .5);
    border-radius: 24px;
    color: var(--blox-color-white);
    height: 40px;
    padding: 11px 22px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
}

.PropertyViewerCard_GalleryBtncontent {
    justify-content: center;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
   
}

.PropertyViewerCard_GalleryText {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}

.PropertyViewerCard_NavigationBtnCont {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.PropertyViewercard_NavigationBtn {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: rgba(35, 30, 31, .5);
    border-radius: 24px;
    color: var(--blox-color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.PropertyViewercard_NavigationBtnIcon {
    width: 20px;
    aspect-ratio: auto 20 / 20;
    height: 20px;
}

.PropertyViewerCard_PropertySubData {
    display: flex;
    justify-content: space-between;
}

.PropertyViewerCard_PropertyInfoCont {
    display: none;
}

.PropertyViewerCard_ShortAddressCont {
    display: none;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
}

.PropertyViewerCard_MobileRangelabel {
    font-size: 14px;
    line-height: 20px;
}

.PropertViewerCard_CallRMActionBtnCont {
    display: none;
}

.PropertyViewerCard_MobileCallRMBtn {
    background: hsla(0, 0%, 100%, .15);
   
    box-sizing: border-box;
    color: var(--blox-color-white);
    height: 40px;
    padding: 11px 22px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
}

.MobileCallRMBtn_content {
    justify-content: center;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobileCallRM_Text {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}

.MobileCallRMBtn_Icon {
    width: 16px;
    aspect-ratio: auto 16 / 16;
    height: 16px;
}

.PropertyCardViewer_PropertyTagsCont {
    z-index: 1000;
    display: -webkit-inline-box;
    overflow: auto;
    width: 100%;
    padding: 16px 72px;
    margin-top: 20px;
    background-color: var(--blox-color-thunder);
}

.PropertyViewerCard_SecureIconNameCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px 0 8px;
    gap: 4px;
    height: 32px;
    background-color: var(--blox-color-thunder);
    border-radius: 8px;
    width: -moz-auto;
    width: auto;
}

.PropertyViewerCard_SecureIcon {
    width: 24px;
    aspect-ratio: auto 24 / 24;
    height: 24px;
}

.PropertyViewerCard_SecureIconName {
    color: var(--blox-color-dusty-gray);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}



.PropertyViewer_SliderCounter {
    display: flex;
    justify-content: center;
    max-width: 100%;
    flex-wrap: nowrap;
    position: absolute;
    width: 33%;
    padding: 7px 0px 0px;
    align-self: center;
    z-index: 1001;
    filter: drop-shadow(rgb(34, 34, 34) 0px 1px 8px);
    transition: opacity 400ms ease-in-out 0s;
    left: 0px;
    margin-left: 36px;
    bottom: -20px;
    opacity: 1;
}

.PropertyCardViewer_ProgressBar {
    height: 2px;
    max-width: 100%;
    background: rgb(85, 85, 85);
    margin: 2px;
    border-radius: 2px;
    backface-visibility: hidden;
    width: 12.5%;
}

.PropertyCardViewer_ProgressBar_innerBar {
    background: rgb(255, 255, 255);
    height: 100%;
    max-width: 100%;
    border-radius: 2px;
    transform-origin: left center;
    backface-visibility: hidden;
    perspective: 1000px;
    width: 100%;
    transform: scaleX(0);
}

.PropertyCardViewer_ProgressBar_innerBar.active {
    animation: progressBar ease-in-out 13s forwards;
}

@keyframes progressBar {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}



.Property_NavigationBar__StickyCont {
    position: -webkit-sticky;
    position: sticky;
    top: 55px;
    width: 100%;
    z-index: 10000;
}

.PropertyNavigationBar_NavBar {
    overflow: auto;
    white-space: nowrap;
    background-color: var(--blox-color-thunder);
    width: -webkit-fill-available;
    padding: 10px 72px;
    -ms-overflow-style: none;
    height: 40px;
}

.PropertyNavigationBar__NavLinks {
    display: inline-block;
    text-align: center;
    padding: 0 14px;
    height: 100%;
    text-decoration: none;
    color: var(--blox-color-dusty-gray);
    cursor: pointer;
}

.PropertyNavigationBar__NavLinksText {
    line-height: 28px;
    font-size: 14px;
    line-height: 20px;
}

.PropertyNavigationBar_NavNtnCont {
    display: none;
}

.PropertyNavbar_BooAVisitBtnCont {
    margin: 3.5px;
    width: 50%;
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-thunder);
    height: 40px;
    padding: 11px 22px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
}

.PropertyNavBar_Content {
    justify-content: center;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PropertyNavBar_Text {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}



.PropertyDetails_RelativePropertyCont {
    width: 100%;
    background-color: var(--blox-color-pampas-bg-color);
    padding: 0 72px;
   
}

.PropertyDetails_WrapperCont {
    display: flex;
    padding: 30px 0;
    justify-content: space-between;
   
}

.PropertyDetails_LeftSection {
    width: 70%;
    padding-right: 50px;
}

section {
    display: block;
}

.PropertyDetails_Leftsection__Overview {
    display: flex;
    margin-bottom: 64px;
    position: relative;
}

.LeftSection_OverviewTitle {
    padding-right: 30px;
    color: var(--blox-color-thunder);
    font-weight: 400;
}

.LeftSecton_OverviewTitle__Text {
    font-size: 16px;
    line-height: 22px;
}

.LeftSection_OverviewData {
    padding-top: 5px;
}

.Overview_TruncatedTextCont {
    display: flex;
    gap: 5px;
    text-align: justify;
}

.OverviewTruncatedText {
    color: var(--blox-color-scorpion);
    font-size: 12px;
    -webkit-line-clamp: 1;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
   
    text-overflow: ellipsis;
    white-space: normal;
    display: inline;
}

.TruncatedText_ReadMoreBtn {
    display: inline;
    color: var(--blox-color-dune);
    font-size: 12px;
    flex: none;
    cursor: pointer;
}

.Overview_OverviewGrid {
    margin-top: 20px;
    display: grid;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: auto auto;
}

.Overview_OverviewSubGrid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
}

.OverviewSubgrid_Overviewlabel {
    color: var(--blox-color-dusty-gray);
    font-size: 12px;
    line-height: 18px;
}

.OverviewSubGrid_OverviewData {
    color: var(--blox-color-thunder);
    font-size: 14px;
    font-size: 14px;
    line-height: 20px;
}

.OverviewLeftSection_OverviewListCont {
    max-height: none;
    overflow: initial;
    position: relative;
}

.OverviewSquareList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.OverviewSquareList li {
    font-size: 15px;
    color: var(--blox-color-thunder);
    line-height: 20px;
    position: relative;
    padding: 3px 0 3px 20px;
    display: flex;
    align-items: center;
    gap: 3px;
}
.BestpropdealAdvangeImgp {
    width: 16px;
    aspect-ratio: auto 16 / 16;
    height: 16px;
    color: #c27e54;
    align-items: center;
}


.OverviewLeftSection_OverviewBtn {
    display: none;
    background-color: var(--blox-color-thunder);
    color: var(--blox-color-white);
    height: 32px;
    padding: 7px 20px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
}

.OverviewLeftSection_OverviewBtncontent {
    justify-content: center;
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.OverviewLeftSection_OverviewBtnText {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}





.Amenities_AmenitiesCard {
    margin-bottom: 40px;
    height: auto;
    width: auto;
    padding: 24px;
    border-radius: 24px;
    background: var(--blox-color-white);
    border: 1px solid var(--blox-color-white);
}

.Amenities_AmenitiesTitle {
    margin-bottom: 20px;
    font-weight: 400;
    position: relative;
    color: #000;
    font-size: 16px;
    line-height: 22px;
}







.Amenities_TagGrid {
    display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 10px; 
}

.AmenitiesTagGrid_Element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.AmenitiesTagGrid_Element div {
    display: block;
    padding: 0 0 0 10px;
    color: var(--blox-color-dusty-gray);
}










.Amenitiesseeall_Text {
    border:none;
    padding-left: 0;
    color: var(--blox-color-thunder);
    height: 40px;
    padding: 11px 22px;
    cursor: pointer;
    gap: 8px;
    display: flex;
    flex-direction: row;
    background-color: inherit;
    width: 100%;
}

.Amenities_AmenitiesMobile {
    display: none;
}

.Amenities_HorizantalSwiper {
    display: flex;
    align-content: center;
    justify-content: center;
    touch-action: pan-y;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.Amenities_SwiperWrapper {
    transition-duration: 0ms;
    transform: translateZ(0);
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}

.AmenitiesSwiper_SwiperSlide {
    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    transition-property: transform;
}

.Amenities_AmenitiesTagGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-gap: 15px;
}

.Amenities_IconLabel {
    display: flex;
    align-items: center;
    font-size: 12px;
    text-align: left;
}

.AmenitiesIcon {
    width: 20px;
    aspect-ratio: auto 20 / 20;
    height: 20px;
}

.Amenities_IconLabel div {
    display: block;
    padding: 0 0 0 10px;
    color: var(--blox-color-dusty-gray);
}

.AmenitiesSwiper_PAginationClickable {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    position: absolute;
    text-align: center;
    transition: opacity .3s;
    transform: translateZ(0);
    z-index: 10;
}

.SwiperPagination_Bullets {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    display: none !important;
    cursor: pointer;
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
}



.PropertyDetailsFloorPlanCont {
    margin-bottom: 40px;
}



.PriceFloorPlan_PlanCard {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 24px;
    background: var(--blox-color-white);
    border: 1px solid var(--blox-color-white);
}

.PriceFloorPlan_TagHeadercont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    align-items: center;
}

.PriceFloorPlan_TagHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.PriceFloorPlan_HeadingCont {
    display: flex;
    gap: 2px;
    flex-direction: column;
}

.PriceFloorPlan_TextTitle {
    color: var(--blox-color-thunder);
    font-weight: 400;
}

.PriceFloorPlan_TextTitle {
    font-size: 16px;
    line-height: 22px;
}

.PriceFloorPlan_Header {
    color: var(--blox-color-dusty-gray);
    font-weight: 400;
}

.PriceFloorPlan_HeaderText {
    font-size: 14px;
    line-height: 20px;
}

.PriceFloorPlan_TagsNav {
    display: flex;
    width: auto;
    grid-gap: 10px;
    overflow: auto;
}

.PriceFloorPlan_TagNavBtn {
    letter-spacing: 1px;
   
    height: 32px;
    padding: 7px 20px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.PriceFloorPlan_TagNavBtn.active {
    background-color: var(--blox-color-thunder);
    color: var(--blox-color-white);
}

.PriceFloorPlan_TagNavBtn.inactive {
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-thunder);
}

.PriceFloorPlan_TagNavBtn__Textcont {
    justify-content: center;
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PriceFloorPlan_TagNavBtn__Text {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}

.PriceFloorPlan_CollapsbleContent {
    transition: max-height .2s ease-out;
    display: block;
}

.PriceFloorPlan_TabCont {
    padding: 20px 10px 0;
}

.PriceFloorPlan_HorizantalSwiper {
    display: flex;
    align-content: center;
    justify-content: center;
    touch-action: pan-y;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.PriceFloorPlan_SwipweWrapper {
    transform: translate3d(0px, 0px, 0px);
    transition-duration: 0ms;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}

.PriceFloorPlan_SwiperSlide {
    width: 100%;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
    max-height: 100%;
   
    align-content: center;
    justify-content: center;
    position: relative;
    transition-property: transform;
}

.PriceFloorPlan_SwiperSlide.active {
    display: flex;
}

.PriceFloorPlan_SwiperSlide.inactive {
    display: none;
}

.PriceFloorPlan_SwiperSlidecont {
    min-width: 100%;
    min-height: 250px;
    text-align: center;
}

.PriceFloorPlan_SwiperSlidecont img {
    width: 300px;
    aspect-ratio: auto 300 / 300;
    height: 300px;
}

.PriceFloorPlanDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-bottom: 32px;
    gap: 24px;
}

.PriceFloorPlan_CarpetAreaCont {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-end;
}

.PriceFloorPlan_DeatailHeading {
    color: var(--blox-color-scorpion);
    font-size: 14px;
    line-height: 20px;
}

.PriceFloorPlan_DeatailData {
    font-weight: 500;
    color: var(--blox-color-thunder);
    font-size: 16px;
    line-height: 20px;
}

.PriceFloorPlan_PriceRangeCont {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
}

.PriceFloorPlan_SwiperPagination {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    position: absolute;
    text-align: center;
    transition: opacity .3s;
    transform: translateZ(0);
    z-index: 10;
}

.PriceFloorPlan_PaginationBullets {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    cursor: pointer;
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
}

.PriceFloorPlan_PaginationBullets.active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: blue
}

.PriceFloorPlan_SwiperNavBtnCont {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.PriceFloorPlan_SwiperNavBtn {
    z-index: 5;
}

.PriceFloorPlan_SwiperNavIconBtn {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-scorpion);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.PriceFloorPlan_SwiperNavIcon {
    width: 16px;
    aspect-ratio: auto 16 / 16;
    height: 16px;
}

.LocalityCard {
    padding: 24px;
    border-radius: 24px;
    background: var(--blox-color-white);
    border: 1px solid var(--blox-color-white);
}




.Locality_Title {
    text-align: left;
    color: var(--blox-color-thunder);
    margin-bottom: 25px;
    font-weight: 400;
}



.Locality_LocalityRow {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.LocalityRowDetails {
    width: 100%;
    height: -moz-auto;
    height: auto;
}

.LocalityRow_SameLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.LocalityRow_Dest {
    color: var(--blox-color-scorpion);
    font-size: 14px;
    line-height: 20px;
}

.LocalityRow_DestTime {
    color: var(--blox-color-dusty-gray);
    font-size: 12px;
    line-height: 18px;
}

.LocalityRow_Indicator {
    height: -moz-auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 7px;
}

.LocalityRow_IndicatorBar {
    height: 2px;
    width: 100%;
    background-color: var(--blox-color-dune);
    border-radius: 4px;
}

.LocalityPlaces {
    display: flex;
    flex-direction: row;
   
    gap: 14px;
    margin: 16px 0;
}

.LocalityPlaces_BtnCont {
   
    height: 32px;
    padding: 10px 25px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
   
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
    align-items: center;
   
}
.LocalityPlaces_BtnCont.active {
    background-color: var(--blox-color-thunder);
    color: var(--blox-color-white);
}
.LocalityPlaces_BtnCont.inactive {
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-thunder);
}





.LocalitySlider {
    display: flex;
    align-content: center;
    justify-content: center;
   
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.LocalitySlider_Wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}

.LocalitySwiper_Slide {
    margin-right: 8px;
    width: 250px;
    flex-shrink: 0;
   
    height: 100%;
    max-height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    transition-property: transform;
    cursor: pointer;
}



.LocalitySlider_LocationCard {
    height: auto;
    width: auto;
    padding: 24px;
    border-radius: 24px;
    background: var(--blox-color-ebb-divider);
}

.LocationCard_Header {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.LocationCard_HeaderTitle {
    color: #6e6e6e;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.LocationCard_HeaderText {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.LocationCard_LocatlityDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
    gap: 65px;
}

.LocalityRating_Cont {
    display: flex;
    flex-direction: row;
}

.LocalityRating_Text {
    color: var(--blox-color-thunder);
    margin-right: 4px;
    font-size: 12px;
    line-height: 18px;
}

.LocalityRating_NumText {
    color: var(--blox-color-dusty-gray);
    font-size: 12px;
    line-height: 18px;
}

.LocalityDistText {
    font-size: 11px;
    color: var(--blox-color-dusty-gray);
}


.LocalitySlider_NavBtn {
    position: relative;
    bottom: 72px;
    margin-right: -20px;
    margin-left: -20px;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.LocalitySwiper_NavigationBtn {
    z-index: 5;
}

.LocalitySwiper_NavIconBtn {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-scorpion);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.LocalitySwiper_NavIcon {
    width: 16px;
    aspect-ratio: auto 16 / 16;
    height: 16px;
}

/* #QRCodes{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 200px;
}

#QRCodes img{
    object-fit: contain;
} */

.qr-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:15px;
    margin-top: 20px;
}

.qr-container {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .qr-incont{
    width: 30%;
} */

.qr-image {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
}
.qr-details{
    margin-top: 10px;
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.qr-details p {
    margin: 0;
    font-size: 14px;
    color: #fff;

}

.developer_div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.developer_img {
    width: 128px;
    height: 128px;
    margin:  auto;
    border-radius: 10px;
    border: 1.65px solid #ece7e4;
    box-shadow: 0 8.53333px 17.0667px rgba(0, 0, 0, .08);
    object-fit: contain;
    align-items: center;
    display: flex;
    justify-content: center;
}

.developer_squarebox {
    position: relative;
    text-align: center;
}





.develop_info_title {
    color: var(--blox-color-thunder);
    font-weight: 400;
    font-size: 20px;
}





.developer_description {
    font-size: 14px;
    color: var(--blox-color-thunder);
   
  
    margin-bottom: 0;
    display: box;
   
  
}

.developer_readmore_container {
    margin-bottom: 15px;
    background-color: transparent;
    color: var(--blox-color-thunder);
    border-color: transparent;
    height: 40px;
    padding: 11px 22px;
    cursor: pointer;
    justify-content: center;
    height:auto;
    gap: 8px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 10px;
}




.developer_description.hide{
    display:none
}
.developer_description .show{
    display:block;
}


.faqcard_componemt {
    padding: 22px;
    background: var(--blox-color-white);
    border: 1px solid var(--blox-color-white)
}



.faqcard_heading {
    font-weight: 400;
    font-size: 25px;
    line-height: 20px;
    color: var(--blox-color-thunder);
}
.faq_question {
    cursor:pointer;
    border-bottom: 1px solid var(--blox-color-ebb-divider);
    display: flex;
    background-color: inherit;
    padding: 18px 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}





.faq_question_cont_heading {
    color: var(--blox-color-thunder);
    font-weight: 400;
    font-size: 12px;
   
}




.faq_question_arrow {

    width: 30px !important;
    height: 30px;
    border-radius: 50%;
    display: flex;

    justify-content: center;
    align-items: center;
  
}

.faq_question_desc_container {
    border-bottom: 1px solid var(--blox-color-ebb-divider);
    max-height: max-content;
    padding: 24px 18px;
    overflow: hidden;
    color: var(--blox-color-thunder);
    font-size: 12px;

  
}

.faq_question_desc_container.show {
    display: block;
}

.faq_question_desc_container.hide {
    display: none;
}



.property_DeatilesCont{
  
    height: 5748px;
    position: absolute;
    right: 80px;
    z-index: 99999999;
   
}
.property_Deatiles {
    height: 100dvh;
    position: -webkit-sticky;
    position: sticky !important;
    z-index: 9999999;
    top: 65px;
    padding: 0 !important;
    margin-top: -225px;
    background-color: transparent;
}

.property_Deatiles_rmcard {
    height: -moz-auto;
    height: auto;
    overflow: hidden;
    margin-bottom: 24px;
}

.property_Deatiles_rmcard_cont {
    padding: 0 !important;
    position: relative;
   

   
    border-radius: 24px;
    background: var(--blox-color-thunder);
}







.property_Deatiles_rmcard_content_title {
    padding: 0px 24px;
    color: var(--blox-color-white);
    font-weight: 500;
    font-size: 20px;
}

.property_Deatiles_rmcard_content_row_star{
    margin-right: 5px;
}




.property_Deatiles_rmcard_middle_text {
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    text-align: center;
}





.property_Deatiles_rmcard_middle_cont_head {
    width:100%;
    height: 40px;
    color: #fff;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}





.property_Deatiles_rmcard_middle_cont_head_text {
    display:flex;
    align-items: center;
    gap: 3px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}


.property_deatiles_callrm_button_container {
    display: none;
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-thunder);
    height: 32px;
    padding: 7px 20px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
}

.property_deatiles_callrm_button {
    justify-content: center;
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.property_deatiles_callrm_buttontext {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}

.property_Deatiles_rmcard_middle_img {
   
    display: flex;
    flex-direction: column-reverse;
}

.property_Deatiles_rmcard_middle_image {
    float: right;
    object-fit: contain;
}

.property_Deatiles_rmcard_bottom_container {
    align-content: center;
    text-align: center;
    background-color: var(--blox-color-scorpion);
    border-radius: 0 0 24px 24px;
    padding: 24px;
    height: -moz-auto;
    height: auto;
    gap: 16px;
}

.property_Deatiles_rmcard_bottom_cont {
    display: block;
    margin: auto;
    width: 100%;
    background-color: var(--blox-color-thunder);
    color: var(--blox-color-white);
    height: 48px;
    padding: 15px 24px;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
}

.property_Deatiles_rmcard_bottom_callrm_butt {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.property_Deatiles_rmcard_bottom_callrm_butt_text {
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}

.property_Deatiles_rmcard_bottom_cont_space {
    color: var(--blox-color-dusty-gray);
    text-align: left;
    margin-top: 14px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
}

.property_Deatiles_rmcard_bottom_content {
    margin: auto;
    width: 100%;
    background-color: var(--blox-color-ebb-divider);
    color: var(--blox-color-thunder);
    height: 48px;
    padding: 15px 24px;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
}

.property_Deatiles_enquirycard {
    width: 328px;
}

.property_Deatiles_enquirycard_cont {
    padding: 20px;
    border-radius: 20px;
    background-color: var(--blox-color-dune);
    position: relative;
}

.property_Deatiles_enquirycard_form {
    width: 100%;
    height: -moz-auto;
    height: auto;
}

.property_Deatiles_enquirycard_form_header {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.property_Deatiles_enquirycard_form_header_cont {
    color: var(--blox-color-white);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.property_Deatiles_enquirycard_form_heading {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
}

.property_Deatiles_enquirycard_form_header_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.property_Deatiles_enquirycard_form_body {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.property_Deatiles_enquirycard_form_body_heading {
    color: var(--blox-color-dusty-gray);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.property_Deatiles_enquirycard_form_body_input {
    padding: 0 !important;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.property_Deatiles_enquirycard_form_body_input_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.property_Deatiles_enquirycard_form_body_input_text {
    height: 29px;
    background-color: var(--blox-color-dune) !important;
    color: var(--blox-color-white);
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    background: none;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none
}

.property_Deatiles_enquirycard_form_body_input_errormsg {
    min-height: 16px;
    width: 100%;

}

.property_Deatiles_enquirycard_form_body_input_errormsg_cont {
    color: var(--blox-color-burnt-sienna);
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
}

.property_Deatiles_enquirycard_form_body_contact {
    width: 100%;
    display: inline-flex;
    gap: 10px;
    align-items: flex-start;
}

.property_Deatiles_enquirycard_form_body_contact_icon {
    padding: 0 !important;
    display: flex;
    flex-direction: column-reverse;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
    border: none;
}

.property_Deatiles_enquirycard_form_body_contact_icon_cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 100%;
}

.property_Deatiles_enquirycard_form_body_contact_icon_select {
    width: 64px;
    height: 29px;
    background-color: var(--blox-color-dune) !important;
    color: var(--blox-color-white);
    background: none;
    border: none;
    border-bottom: 1px solid #979393;
}

.property_Deatiles_enquirycard_form_body_contact_cont {
    padding: 0 !important;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 2px
}

.property_Deatiles_enquirycard_form_body_checkbox {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 14px;
}

.property_Deatiles_enquirycard_form_body_checkbox_cont {
    padding: 0 !important;
    width: -moz-auto;
    width: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.property_Deatiles_enquirycard_form_body_checkbox_desc {
    color: #979393;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
}

.property_Deatiles_enquirycard_form_body_checkbox_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.property_Deatiles_enquirycard_form_body_checkbox_checkplace {
    height: 20px;
    width: 20px;
    color: var(--blox-color-white);
    font-size: 18px;
    line-height: 20px;
    background: none;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
}


.property_Deatiles_enquirycard_form_body_callback_btn {
    margin: auto;
    width: 100%;
    background-color: var(--button-active-secondary-disabled-bg-color) !important;
    color: var(--blox-color-dusty-gray) !important;
    cursor: auto;
    height: 48px;
    padding: 15px 24px;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
    width: -moz-auto;
    width: auto;
}

.property_Deatiles_enquirycard_form_body_callback_btn_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.property_Deatiles_enquirycard_form_body_callback_btn_text {
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}


.property_Deatiles_rmcard_callrm_form_container {
    align-items: center;
    animation: Modal_slideInTop__m5jCF .5s forwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999 !important;
    background-color: rgba(0, 0, 0, .5);
}

.property_Deatiles_rmcard_callrm_form_containerdisplay {
    display: none !important;
    z-index: 99999999999999;
}

.property_Deatiles_rmcard_callrm_form_cont {
    background-color: #fff;
    position: relative;
}

.property_Deatiles_rmcard_callrm_form_xbutt {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 200;
    background-color: hsla(0, 0%, 100%, 0.201);
    color: var(--blox-color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.property_Deatiles_rmcard_callrm_form_content {
    display: flex;
    grid-gap: 20px;
    height: 100%;

}

.property_Deatiles_rmcard_callrm_form_cont_body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    justify-content: space-between;
    background: linear-gradient(230deg, var(--blox-color-dune) 0, var(--blox-color-dune) 20%, var(--blox-color-thunder) 20%, var(--blox-color-thunder) 85%, var(--blox-color-burnt-sienna) 85%, var(--blox-color-burnt-sienna) 100%);
    flex: 1 1;
}

.property_Deatiles_rmcard_callrm_form_cont_body_deatiles {
    color: var(--blox-color-white);
    font-size: 12px;
    line-height: 18px;
}

.property_Deatiles_rmcard_callrm_form_cont_body_img_container {
    flex-direction: row;
    display: flex;
}

.property_Deatiles_rmcard_callrm_form_cont_body_img_cont_down {
    font-size: 12px;
    line-height: 18px;
}

.property_Deatiles_rmcard_callrm_form_cont_body_img_cont_down_star {
    display: flex;
    grid-gap: 10px;
    margin: 10px 0;
}

.property_Deatiles_rmcard_callrm_form_cont_body_img_cont_down_star_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.property_Deatiles_rmcard_callrm_form_cont_desc {
   
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1 1;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_logo {
    color: #000;
    font-weight: 500;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_heading {
    color: var(--blox-color-thunder);
    margin-bottom: 20px;
    font-weight: 400;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_headingtext {

    font-size: 20px;
    line-height: 28px;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_inputfeild {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_inputfeild_cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_inputfeild_content {
    background: transparent;
    flex-grow: 1;
    padding: 8px;
    border-width: 0.5px;
    color: var(--blox-color-thunder);
    font-size: 14px;
    line-height: 20px;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_inputfeild_space {
    min-height: 16px;
    width: 100%;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_inputfeild_content_errmsg {
    color: red
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_icon {
    padding: 0;
    width: -moz-auto;
    width: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
    border: none;
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_icon_cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 100%;
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_icon_select {
    padding: 10px 8px;
    background-color: transparent;
    color: var(--blox-color-thunder);
    height: 40px;
    background: none;
    border: none;
    border-bottom: 1px solid #979393;
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_desc {

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_desc_cont {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_desc_text {
    padding: 10px 8px;
    background-color: transparent;
    flex-grow: 1;
    width: 100%;
    border-width: 0.5px;
    color: var(--blox-color-thunder);
    font-size: 14px;
    line-height: 20px;
    background: none;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_desc_errmsg {
    flex-grow: 1;
    min-height: 16px;
    width: 100%
}

.property_Deatiles_rmcard_callrm_form_contact_inputfeild_desc_errmsgcont {
    flex-grow: 1;
    color: var(--blox-color-burnt-sienna);
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;

}

.property_Deatiles_rmcard_callrm_form_cont_desc_regcallback_button {
    margin: 20px 0;
    width: 100%;
    font-weight: 700;
    background-color: var(--button-active-primary-disabled-bg-color) !important;
    color: var(--blox-color-dusty-gray) !important;
    cursor: auto;
    height: 40px;
    padding: 11px 22px;
    width: -moz-auto;
    width: auto;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    ;
    letter-spacing: 2px;
}

.property_Deatiles_rmcard_callrm_form_cont_desc_regcallback_buttoncont {
    justify-content: center;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

}


.property_Deatiles_rmcard_callrm_form_cont_desc_regcallback_buttontext {
    width: 100%;
    white-space: nowrap;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

.Locality_slide {
    height: auto;
}







.SameArea_Properties__Cont {
    height: auto;
    width: auto;
    border-radius: 0;
    padding: 0 !important;
    background-color: transparent;
    position: relative;
   
}

.SameArea_Properties__CardHeader {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.SameArea_CardHeader_Textcont {
    color: var(--blox-color-thunder);
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
}

.SameArea_CardHeader_Title {
    font-weight: 400;
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.SameArea_CardHeader_Text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: var(--blox-color-thunder);
}
.SameArea_CardHeader_TextNo {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    color: var(--blox-color-thunder);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.SameArea_CardHeader_Iconcont {
    display: flex;
    justify-content: center;
    align-items: center;
}





.slides-wrapper-samearea {
    display: flex;
    transition: transform 0.3s ease;
    gap: 20px;
}


.same_area {
    background-color: var(--blox-color-pampas-bg-color) !important;
}

.same_area::before {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    height: calc(100% - 40px);
    right: 0;
    z-index: 2;
    background: linear-gradient(90deg, hsla(30, 11%, 96%, 0), #f7f6f5);
}

/* .samearea_SwiperPagination {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    position: absolute;
    text-align: center;
    transition: opacity .3s;
    transform: translateZ(0);
    z-index: 10;
}
 */

.Same_area_PaginationBullets {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    cursor: pointer;
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
}

.Same_area_PaginationBullets.active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: blue
}

.Same_area_SwiperNavBtnCont {
    display: flex;
    align-content: center;
    justify-content: space-between;
}



.StickyWrapper_Navbar {
    position: -webkit-sticky;
    position: sticky;
    z-index: 999999999999999;
    transition: all .2s ease-in;
    width: 100%;
    transition: all .2s ease-in-out;
}

.StickyWrapper_Navbarcont {
    position: fixed;
    background-color: var(--blox-color-thunder);
    top: 20px;
    width: 100%;
    z-index: 999999999999999;
    left: 0;
    right: 0;
    animation: PropertyDetailHeaderStickyBar_mymove__qPZol .2s forwards;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes PropertyDetailHeaderStickyBar_mymove__qPZol {
    0% {
        top: 20px;
    }

    100% {
        top: 0px;
    }
}

.StickyWrapper_NavbarGridCont {
    display: flex;
    height: 100%;
    margin-left: 85px;
    margin-right: 85px;
    justify-content: space-between;
    align-items: center;
}

.StickyWrapper_NavbarGridHeader {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.StickyWrapper_NavbarTitle {
    font-size: 14px;
    line-height: 20px;
}

.StickyWrapper_NavbarRightData {
    display: flex;
    align-items: center;
    grid-gap: 30px;
}

.StickyWrapper_NavbarShareIconCont {
    width: 20px;
    height: 20px;
    border-radius: 0px;
    background-color: transparent;
    color: var(--blox-color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}



.propertyseoLinks_cont {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.BookSiteVisit_Cont {
    width: 60%;
    height: 460px;
    border-radius: 0;
    background-color: var(--blox-color-white) !important;
    overflow-x: hidden;
    overflow-y: auto;
    max-block-size: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
    position: relative;
}

.BookSiteVisit_Cont_Form {
    display: flex;
    height: 100%;
}

.BookSiteVisit_FormModal {
    background: var(--blox-color-white);
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
}

.BookAVisit_FormBanner {
    background-image: url("https://www.bestpropdeal.com/wp-content/uploads/2022/08/cam04-aniket-488x326.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 1;
    position: relative;
    padding: 40px;
}

.FormBanner_Quote {
    margin-top: 30px;
}

.FormBanner_QuoteHeading {
    color: var(--blox-color-white);
    font-weight: 400;
}

.FormBanner_QuoteHeadingText {
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1px;
}

.BookAVisit_Form {
    flex: 1 1;
    background-color: var(--blox-color-thunder);
    overflow: auto;
    padding: 40px;
}

.BookAVist_FormLoginModalBox {
    flex-direction: column;
    justify-content: space-between;
}

.BookAVist_FormLoginModalBox.active {
    display: flex;
}

.BookAVist_FormLoginModalBox.inactive {
    display: none;
}

.LoginForm_Header {
    margin-top: 20px;
    flex-direction: column;
    grid-gap: 15px;
}

.LoginForm_Title {
    color: var(--blox-color-white);
    font-weight: 400;
}

.LoginForm_TitleText {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.LoginForm_FormCont {
    padding: 40px 0;
}

.LoginForm_LoginModal_Phonecont {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.LoginModal_DropdownCont {
    padding: 0;
    width: -moz-auto;
    width: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
    border: none;
}

.LoginDropdownCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 100%;
}

.LoginDropdown_select {
    padding: 6px 8px;
    background-color: transparent;
    color: var(--blox-color-white);
    height: 33px;
    background: none;
    border: none;
    border-bottom: 1px solid #979393;
}

.LoginDropdown_option {
    font-weight: normal;
    display: block;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    min-height: 1.2em;
}

.LoginForm_LoginModalPhoneNoCont {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 700;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.LoginForm_LoginModalPhoneNoInput {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.LoginForm_PhoneNoInput {
    padding: 6px 8px;
    background-color: transparent;
    flex-grow: 1;
    color: var(--blox-color-white);
    width: 100%;
}

.LoginModalPhoneNo_ErrorMessageWrapper {
    flex-grow: 1;
    min-height: 16px;
    width: 100%;
}

.LoginModalPhoneNo_ErrorMessageWrapper {
    color: var(--blox-color-burnt-sienna);
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
}

.LoginForm_ContinueBtn {
    margin: 20px 0;
    width: 100%;
    font-weight: 700;
    background-color: var(--button-active-secondary-disabled-bg-color) !important;
    color: var(--blox-color-dusty-gray) !important;
    cursor: auto;
    height: 40px;
    padding: 11px 22px;
    width: -moz-auto;
    width: auto;
    cursor: pointer;
    border-radius: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-style: normal;
    text-align: center;
    letter-spacing: 2px;
}

.LoginForm_ContinueBtnTextCont {
    justify-content: center;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LoginForm_ContinueBtnText {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
}

.LoginModal_VerificationCont {
    flex-direction: column;
    justify-content: space-between;
}

.LoginModal_VerificationCont.active {
    display: flex;
}

.LoginModal_VerificationCont.inactive {
    display: none;
}

.LoginModal_VerificationCont_BackIconCont {
    width: 20px;
    height: 20px;
    border-radius: 0px;
    margin-top: 20px;
    background: transparent;
    color: var(--blox-color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.LoginModal_Verification_OPTCont {
    margin: 10px 0;
    display: flex;
    grid-gap: 10px;
    width: 80%;
}

.LoginModal_Verification_OPP {
    display: flex;
    align-items: center;
}

.LoginModal_Verification_OPPInput {
    text-align: center;
    padding: 5px;
    font-size: 24px;
    width: 40px !important;
    background: transparent;
    border: 0 solid transparent;
    border-bottom: 1px solid var(--blox-color-dusty-gray);
    color: var(--blox-color-white);
}

.LoginModal_Verification_OPP span {
    padding: 0 5px;
}

.LoginModal_Verification_Error {
    color: var(--blox-color-burnt-sienna);
    font-size: 11px;
    line-height: 16px;
}

.LoginModal_Verification_OTPTimer {
    padding: 15px 0;
    margin: 10px 0 20px;
    width: 150px;
    border-bottom: 1px solid var(--blox-color-dusty-gray);
    color: var(--blox-color-dusty-gray);
    font-size: 14px;
}

.LoginModal_Verification_OTPFailed {
    color: var(--blox-color-dusty-gray);
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
}

.LoginModal_Verification_OTPResend {
    padding-top: 10px;
    cursor: pointer;
    width: 65px;
}

.LoginModal_Verification_OTPResendText {
    font-size: 14px;
    line-height: 20px;
}

.PropertyViewerCard_PropertyActnBtnPRangeCtn {
    display: none;
}

.PropertyViewerCard_PropertySubDataCtn__ShLIt {
    display: none;
}

.StickyWrapper_NavbarShareIconContMobile {
    display: none;
}

.StickyFooter {
    display: none;
}
.FormMobile_Cont{
    display: none;
}

.videoGalleryCont{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.videoGalleryVideo{
    width: 100% !important;
    height: 600px;
    object-fit: fill;
}

@media (max-width: 768px) {
    .LeftSecton_OverviewTitle__Text{
        font-size: 20px;
    }
    .PropertyCardViewer_MediaContent {
        width: 100%;
        height: 492px;
        position: relative;
    }

    .PropertyCardViewer_CardDataCont {
        height: 100%;
        justify-content: space-between;
    }

    .PropertyCard_NavigatorCont {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 76px 48px 0;
    }

    .PropertyCard_DataCont {
        padding: 50px 16px 16px;
    }

    .PropertyViewerCard_PropertyIcon {
        border-radius: 16px;
        margin-bottom: 16px;
        height: 70px;
        width: 70px;
    }

    .PropertyViewerCard_MediaScroller {
        height: 600px;
    }
    .PropertyCardViewer_MediaContent{
        height: 600px;
    }

    .PropertyViewerImg {
        height: 800px;
        aspect-ratio: auto 844 / 844;
        animation-name: imagetopdown;
        animation-duration: 13s;
    }

    .PropertyViewerCard_PropertyTitleText {
        font-size: 30px;
        font-weight: lighter;
    }

    .PropertyViewerCard_PropertyNameCont {
        margin-bottom: 12px;
    }

    .PropertyViewerCard_PropertyLongAddressCont {
        display: none;
    }

    .PropertyViewerCard_PropertyActionBtnSetright {
        display: none;
    }

    .PropertyViewerCard_PropertyActionBtnRangeCnt {
        display: none;
    }

   
    .PropertyViewerCard_PropertyActnBtnPRangeCtn {
        margin-top: auto;
        display: block;
    }

    .PropertyViewerCard_PropertyCardActionBtn {
        display: flex;
        align-items: unset;
        margin-bottom: 12px;
        gap: 8px;
        justify-content: flex-end;
        flex-direction: column-reverse;
    }

    .PropertyViewerCard_PropActionBtn__K0p_l {
        width: 48px;
        height: 36px;
        border-radius: 100px;
        background: hsla(0, 0%, 100%, .15);
        -webkit-filter: blur(2px);
        filter: blur(2px);
        box-sizing: border-box;
        color: var(--blox-color-white);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .PropertyViewerCard_PropertySubDataCtn__ShLIt {
        display: flex;
        justify-content: space-between;
    }

    .PropertyViewerCard_PropertyInfoCtn__T37bU {
        display: block;
    }

    .PropertyViewerCard_PropertyAddressShort__4deRi {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 4px;
    }

    .PropertyViewerCard_PropertyMobileProce {
        font-size: 19px;
        line-height: 20px;
    }

    .PropertyViewerCard_CallRmActionBtnCtn__SQDNQ {
        display: none;
    }

    .PropertyViewerCard_PropActionBtn__K0p_l {
/*         background: hsla(0, 0%, 100%, .15);
        -webkit-filter: blur(2px);
        filter: blur(2px); */
        box-sizing: border-box;
        color: var(--blox-color-white);
        height: 40px;
        padding: 11px 22px;
        width: -moz-auto;
        width: auto;
        cursor: pointer;
        border-radius: 100px;
/*         -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none; */
        font-style: normal;
        font-weight: 500;
        text-align: center;
        letter-spacing: 2px;
    }

    .Button_ButtonContent-md__1XTQo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .someworstbtn {
        font-size: 18px;
        line-height: 18px;
        width: 100%;
        white-space: nowrap;
    }

    .someworsticon {
        width: 22px;
        aspect-ratio: auto 22 / 22;
        height: 22px;
    }

    .PropertyViewer_SliderCounter {
        width: 100%;
        margin: 0;
        padding: 0;
        bottom: 0;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .PropertyCardViewer_PropertyTagsCont {
        margin: 0;
    }

    .PropertyViewerCard_SecureIconNameCont {
        width: 100%;
        justify-content: flex-start;
    }
    .PropertyViewerCard_SecureIconName{
        font-size: 20px;
    }
    

   

    .property_Deatiles {
        display: none;
    }

    .PropertyNavigationBar_NavBar {
        display: none;
    }

   

   

    .PropertyDetails_RelativePropertyCont {
        padding: 0 10px;
        background-color: var(--blox-color-thunder);
    }

    .PropertyDetails_LeftSection {
        width: 100%;
    }

    .PropertyDetails_LeftSection {
        padding: 0;
    }

    .PropertyDetails_Leftsection__Overview {
        width: 100%;
        margin-bottom: 20px;
    }

    .LeftSection_OverviewTitle {
        padding-right: 10px;
        color: var(--blox-color-white);
        font-size: 14px;
    }

    .OverviewTruncatedText {
        color: var(--blox-color-dusty-gray);
        font-size: 18px;
    }

    .TruncatedText_ReadMoreBtn {
        color: var(--blox-color-dusty-gray);
    }

    .Overview_OverviewGrid {
        gap: 15px;
        grid-template-columns: auto;
    }

    .Overview_OverviewSubGrid {
        grid-gap: 15px;
    }

    .OverviewSubgrid_Overviewlabel {
        font-size: 18px;
    }

    .OverviewSubGrid_OverviewData {
        color: var(--blox-color-white);
        font-size: 14px;
    }

    .OverviewLeftSection_OverviewListCont {
        max-height: 300px;
        position: relative;
        overflow: hidden;
    }

    .OverviewLeftSection_OverviewListCont::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(0deg, #231e1f, rgba(35, 30, 31, 0));
    }

    .OverviewSquareList li {
        color: var(--blox-color-dusty-gray);
    }

   
   
    .Amenities_AmenitiesCard {
        width: 100%;
        background: #423e3c;
        border: none;
    }

    .Amenities_AmenitiesDesktop {
        display: block;
    }

   
    .Amenities_AmenitiesTitle {
        color: var(--blox-color-white);
    }

    .Amenities_TagGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        grid-gap: 13px;
    }

   
    .PriceFloorPlan_TagHeadercont {
        align-items: flex-start;
    }

    .PriceFloorPlan_TagHeader {
        flex-direction: column;
        gap: 20px;
        align-items: start;
    }

    .PriceFloorPlan_TextTitle {
        font-size: 26px;
    }
    .PriceFloorPlan_TagNavBtn__Text{
        font-size:12px ;
    }
  
    .LocalityPlaces_BtnCont{    
        padding: 10px 15px;
    }
    
   

   

    .developer_squarebox {
        width: -moz-auto;
        width: auto;
        height: -moz-auto;
        height: auto;
        margin: 0 auto 16px;
    }

    .developer_img {
        padding:0px;
        width: 95px;
        aspect-ratio: auto 95 / 95;
        height: 95px;
    }
    .develop_info_title{
        color: var(--blox-color-white);
        font-size: 38px;
    }

    .developer_info_title {
        color: var(--blox-color-white);
        padding-bottom: 8px;
    }

    .developer_description {
        color: var(--blox-color-white);
        opacity: .7;
    }

    .developer_readmore_container {
        color: var(--blox-color-white) !important;
        border: transparent;
    }

    .videoGalleryCont{
        height:200px;
        margin-bottom: 15px;
    }
    .videoGalleryVideo{
        height:200px;
    }

    .qr-section{
        margin-top: 15px;
        background-color: transparent;
    }

    .SameArea_CardHeader_Textcont {
        color: var(--blox-color-white);
    }

    .same_area {
        background-color: var(--blox-color-thunder) !important;
    }

    .same_area::before {
        display: none;
    }

    .sliderContainer::-webkit-scrollbar {
        display: none;
    }

   
    .faqcard_componemt {
        padding: 5px;
        background: #423e3c;
        border-color: #383433;
    }

    .faqcard_header {
        color: var(--blox-color-white);
    }

    .faq_question {
        border-color: #564f4c;
    }

    .faq_question_cont_heading {
        color: var(--blox-color-white);
        font-size: 20px;
    }
    .faq_question_desc_container{
        color: var(--blox-color-white);
        font-size: 17px;
    }

    .faq_question_arrow {
        background-color: hsla(0, 0%, 100%, .15);
    }

    .faq_question_desc_text {
        color: var(--blox-color-white);
        border-color: #564f4c;
    }

   
    .StickyWrapper_NavbarPrice {
        display: none;
    }

    .StickyWrapper_NavbarShareIconContMobile {
        display: block;
    }

    .StickyWrapper_NavbarGridCont {
        margin: 0 10px;
    }

   
   
    .StickyFooter {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--blox-color-scorpion) ;
       
        position: fixed;
        bottom: 0;
        top: auto;
        width: 100%;
        z-index: 999999999;
    }

    .StickyFooterNav {
        display: flex;
        margin-bottom: 15px;
       
      
        background-color: var(--blox-color-scorpion);
        width: 100%;
       
      
        padding: 0 20px;
        height: 25px;
        color: hsla(0, 0%, 100%, .5);
    }
   


    .StickyFooterNavLinkText {
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        padding: 14px;   
        cursor: pointer;
    }

  

    .StickyFotterNav_ActionBtncont {
     
        margin: 3px;
        width: 80% !important;
        background-color: var(--blox-color-ebb-divider);
        color: var(--blox-color-thunder);
        height: 40px;
        padding: 11px 22px;
        width: -moz-auto;
        width: auto;
        cursor: pointer;
        border-radius: 100px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 2px;
    }

   

 
    .FormMobile_Cont{
        display: flex;
        gap: 20px;
        margin-bottom: 10px;
    }
    .property_Deatiles_rmcard_bottom_cont{
        width: 328px;
    }
    .property_Deatiles_rmcard_bottom_content{
        width: 328px;
    }
    .property_Deatiles_rmcard{
        width: 400px;
    }
    .property_Deatiles_enquirycard{
        width: 400px;
    }
    .PriceFloorPlan_DeatailHeading{
        font-size: 22px;
    }
    .PriceFloorPlan_DeatailData{
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .PropertyCardViewer_MediaContent {
        width: 100%;
        height: 492px;
        position: relative;
    }

    .PropertyCardViewer_CardDataCont {
        height: 100%;
        justify-content: space-between;
    }

    .PropertyCard_NavigatorCont {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 76px 0px 0;
    }
    .PropertyCard_NavigatorCont{

    }
    .PropertyCard_NavigatorRouteCont{
        width:100%;
    }

    .PropertyCard_DataCont {
        padding: 50px 16px 16px;
    }

    .PropertyViewerCard_PropertyIcon {
        border-radius: 16px;
        margin-bottom: 16px;
        height: 70px;
        width: 70px;
    }

    .PropertyViewerCard_MediaScroller {
        height: 512px;
    }

    .PropertyViewerImg {
        height: 100%;
        width: 844px;
        aspect-ratio: auto 844 / 844;
        animation-name: ImagePanner_WebPan__ikIZ;
        animation-duration: 13s;
    }

    .PropertyViewerCard_PropertyTitleText {
        font-size: 24px;
        font-weight: lighter;
    }

    .PropertyViewerCard_PropertyNameCont {
        margin-bottom: 12px;
    }

    .PropertyViewerCard_PropertyLongAddressCont {
        display: none;
    }

    .PropertyViewerCard_PropertyActionBtnSetright {
        display: none;
    }

    .PropertyViewerCard_PropertyActionBtnRangeCnt {
        display: none;
    }

   
    .PropertyViewerCard_PropertyActnBtnPRangeCtn {
        margin-top: auto;
        display: block;
        font-size: 30px;
    }

    .PropertyViewerCard_PropertyCardActionBtn {
        display: flex;
        align-items: unset;
        margin-bottom: 12px;
        gap: 8px;
        justify-content: flex-end;
        flex-direction: column-reverse;
    }

    .PropertyViewerCard_PropActionBtn__K0p_l {
        width: 48px;
        height: 36px;
        border-radius: 100px;
/*         background: hsla(0, 0%, 100%, .15);
        -webkit-filter: blur(2px);
        filter: blur(2px); */
        box-sizing: border-box;
        color: var(--blox-color-white);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .PropertyViewerCard_PropertySubDataCtn__ShLIt {
        display: flex;
        justify-content: space-between;
    }

    .PropertyViewerCard_PropertyInfoCtn__T37bU {
        display: block;
    }

    .PropertyViewerCard_PropertyAddressShort__4deRi {
        display: block;
        font-weight: 400;
        font-size: 17px;
        line-height: 16px;
    }

    .PropertyViewerCard_PropertyMobileProce {
        font-size: 17px;
        line-height: 20px;
    }

    .PropertyViewerCard_CallRmActionBtnCtn__SQDNQ {
        display: none;
    }

    .PropertyViewerCard_PropActionBtn__K0p_l {
/*         background: hsla(0, 0%, 100%, .15);
        -webkit-filter: blur(2px);
        filter: blur(2px); */
        box-sizing: border-box;
        color: var(--blox-color-white);
        height: 40px;
        padding: 11px 22px;
        width: -moz-auto;
        width: auto;
        cursor: pointer;
        border-radius: 100px;
/*         -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none; */
        font-style: normal;
        font-weight: 500;
        text-align: center;
        letter-spacing: 2px;
    }

    .Button_ButtonContent-md__1XTQo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .someworstbtn {
        font-size: 10px;
        line-height: 18px;
        width: 100%;
        white-space: nowrap;
    }

    .someworsticon {
        width: 16px;
        aspect-ratio: auto 16 / 16;
        height: 16px;
    }

    .PropertyViewer_SliderCounter {
        width: 100%;
        margin: 0;
        padding: 0;
        bottom: 0;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .PropertyCardViewer_PropertyTagsCont {
        margin: 0px;
        padding: 0px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .PropertyViewerCard_SecureIconNameCont {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

   

    .property_Deatiles {
        display: none;
    }

    .PropertyNavigationBar_NavBar {
        display: none;
    }

    .property_Deatiles_rmcard_middle_text_div{
        display: flex;
        flex-direction: column;
    }
    .property_Deatiles_rmcard_middle_text{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width:100% !important;
    }
    .property_Deatiles_rmcard_cont{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        width:100% !important;
    }
    .property_Deatiles_rmcard_content_title{
        width:100% !important;
        align-items: center;
        margin-bottom: 15px;
    }

   

    .PropertyDetails_RelativePropertyCont {
        padding: 0 10px;
        background-color: var(--blox-color-thunder);
    }

    .PropertyDetails_LeftSection {
        width: 100%;
    }

    .PropertyDetails_LeftSection {
        padding: 0;
    }

    .Amenitiesseeall_Text{
        color:#f7f6f5;
    }

    .PropertyDetails_Leftsection__Overview {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .LeftSection_OverviewTitle {
        margin-bottom: 15px;
        padding-right: 10px;
        color: var(--blox-color-white);
        font-size: 14px;
    }

    .OverviewTruncatedText {
        margin-left: 4px;
        margin-right: 4px;
        color: var(--blox-color-dusty-gray);
    }

    .TruncatedText_ReadMoreBtn {
        color: var(--blox-color-dusty-gray);
    }

    .Overview_OverviewGrid {
        gap: 15px;
        grid-template-columns: auto;
    }

    .Overview_OverviewSubGrid {
        grid-gap: 15px;
        place-items: center; 
    }

    .OverviewSubgrid_Overviewlabel {
        font-size: 11px;
    }

    .OverviewSubGrid_OverviewData {
        color: var(--blox-color-white);
        font-size: 12px;
    }

    .OverviewLeftSection_OverviewListCont {
        max-height: 300px;
        position: relative;
        overflow: hidden;
    }

    .OverviewLeftSection_OverviewListCont::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(0deg, #231e1f, rgba(35, 30, 31, 0));
    }

    .OverviewSquareList li {
        color: var(--blox-color-dusty-gray);
    }

   
   
    .Amenities_AmenitiesCard {
        width: 100%;
        background: #423e3c;
        border: none;
    }

    .Amenities_AmenitiesDesktop {
        display: block;
    }

   
    .Amenities_AmenitiesTitle {
        color: var(--blox-color-white);
    }

    .Amenities_TagGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        grid-gap: 13px;
    }

   
    .PriceFloorPlan_TagHeadercont {
        align-items: flex-start;
    }

    .PriceFloorPlan_TagHeader {
        flex-direction: column;
        gap: 20px;
        align-items: start;
    }

    .PriceFloorPlan_TextTitle {
        font-size: 20px;
    }

  
    .LocalityPlaces_BtnCont{    
        padding: 10px 15px;
    }
    
   

   

    .developer_squarebox {
        width: -moz-auto;
        width: auto;
        height: -moz-auto;
        height: auto;
        margin: 0 auto 16px;
    }

    .developer_img {
        padding:0px;
        width: 95px;
        aspect-ratio: auto 95 / 95;
        height: 95px;
        border: 0px !important;
    }
    .develop_info_title{
        color: var(--blox-color-white);
        font-size: 22px;
    }

    .developer_info_title {
        color: var(--blox-color-white);
        padding-bottom: 8px;
    }

    .developer_description {
        color: var(--blox-color-white);
        opacity: .7;
    }

    .developer_readmore_container {
        color: var(--blox-color-white) !important;
        border: transparent;
    }

    .SameArea_CardHeader_Text{
        color:#fff;
    }

    .SameArea_CardHeader_Textcont {
        color: var(--blox-color-white);
    }

    .same_area {
        background-color: var(--blox-color-thunder) !important;
    }

    .same_area::before {
        display: none;
    }

    .sliderContainer::-webkit-scrollbar {
        display: none;
    }

   
    .faqcard_componemt {
        padding: 5px;
        background: #423e3c;
        border-color: #383433;
    }

    .faqcard_header {
        color: var(--blox-color-white);
    }

    .faq_question {
        border-color: #564f4c;
    }

    .extra_styles_faq_heading{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .faq_question_cont_heading {
        color: var(--blox-color-white);
        font-size: 16px;
        width:350px;
    }
    

    .faq_question_desc_container{
        color: var(--blox-color-white);
        font-size: 15px;
    }

    .faq_question_arrow {
        background-color: hsla(0, 0%, 100%, .15);
    }

    .faq_question_desc_text {
        color: var(--blox-color-white);
        border-color: #564f4c;
    }

   
    .StickyWrapper_NavbarPrice {
        display: none;
    }

    .StickyWrapper_NavbarShareIconContMobile {
        display: block;
    }

    .StickyWrapper_NavbarGridCont {
        margin: 0 10px;
    }

   
   
    .StickyFooter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--blox-color-scorpion) ;
       
        position: fixed;
        bottom: 0;
        top: auto;
        width: 100%;
        z-index: 9999999999999;
    }

    .StickyFooterNav {
        display: flex;
        margin-bottom: 15px;
       
      
        background-color: var(--blox-color-scorpion);
        width: 100%;
       
      
        padding: 0 20px;
        height: 25px;
        color: hsla(0, 0%, 100%, .5);
    }
   


    .StickyFooterNavLinkText {
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        padding: 14px;   
        cursor: pointer;
    }

  

    .StickyFotterNav_ActionBtncont {
     
        margin: 3px;
        width: 80% !important;
        background-color: var(--blox-color-ebb-divider);
        color: var(--blox-color-thunder);
        height: 40px;
        padding: 11px 22px;
        width: -moz-auto;
        width: auto;
        cursor: pointer;
        border-radius: 100px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 2px;
    }

   

 
    .FormMobile_Cont{
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;
        gap: 20px;
    }
    .property_Deatiles_rmcard {
        width: 100%;
    }

    .property_Deatiles_rmcard_bottom_cont{
        width: 100%;
    }
    .property_Deatiles_rmcard_bottom_content{
        width: 100%;
    }
    .property_Deatiles_enquirycard{
        width: 100%;
    }
    .property_Deatiles_enquirycard_cont{
        width:100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
    }

    .faqcard_heading{
        color:#fff;
    }
}


@keyframes ImagePanner_WebPan__ikIZ {
    0% {
        transform: translateX(0);
    }

    0% {
        transform: translateX(-25%);
    }
}