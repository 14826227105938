/* .conditions_container{
    background-color: #fff;
    color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 70px;
} */

.terms_container {
    background-color: #fff;
    color: black;
    padding: 2rem;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 20px;
  }
  
  .terms_heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .terms_subheading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  ul {
    margin-left: 2rem; 
  }
  ul li {
    margin-bottom: 1rem;
  }
  ul li p {
    margin-left: 2.5rem; /* Indent paragraph within list item */
  }

  @media (max-width: 768px) {
    .terms_heading{
      font-size: 1.8rem;
    }
    .terms_subheading{
      font-size: 1.3rem;
    }
    ul li p {
      margin-left: 2rem; /* Indent paragraph within list item */
    }
  }
  @media (max-width: 600px) {
    .terms_heading{
      font-size: 1.8rem;
    }
    .terms_subheading{
      font-size: 1.3rem;
    }
    ul li p {
      margin-left: 1.5rem; /* Indent paragraph within list item */
    }
  }