.gallery-container {
    text-align: center;
    padding: 20px;
    margin-top: 70px;
}

.gallery-heading {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.gallery-description {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #666;
}

.gallery-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.gallery-item {
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    margin: 10px;
}

.gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .gallery-item {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .gallery-item {
        flex: 1 1 100%;
    }
}
