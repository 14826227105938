.contact_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #342f30;
    min-height: 60vh;
    width: 100%;
}

.contact_cont {
    width: 100%;
    margin-top: 50px;
}

.heading_cont {
    padding: 22px 72px;
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 200px;
}

.heading_cont img {
    height: 60vh;
    width: 80vh;
}

.heading_text{
    font-size: 64px;
    line-height: 64px;
}

.contact_form_cont{
    width: 100%;
    background-color: var(--blox-color-pampas-bg-color);
    padding: 94px var(--pageMargin);
    position: relative;
}

.form_location_cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 600px;
    padding: 60px;
    gap: 5rem;
}

/* .form_cont{
    background: var(--blox-color-white);
    box-shadow: 0 8px 24px rgba(0, 0, 0, .02);
    border-radius: 24px;
    position: absolute;
    width: 704px;
    left: 72px;
    top: -38px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.property_Deatiles_enquirycard_contactus {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.property_Deatiles_enquirycard_cont_contactus {
    padding: 20px !important;
    position: relative;
    z-index: 99999;
    border-radius: 24px;
    background: #342f30;
    height: 100%;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.location_cont {
    width: 100%;
    height: 100%;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}